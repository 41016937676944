<template>
<v-main class="pt-0 pb-1" id="content">
    <v-app-bar app flat class="app-bar" v-if="!loader" :color="TemaDark?'bk-dark-color':'primary'">
        <v-img src="../../public/img/icons/android-chrome-192x192.png" max-width="40" class="ml-2 hidden-sm-and-down" />
        <v-toolbar-title class="hidden-sm-and-down white--text pl-2 trenos-title-toolbar ">Trenos</v-toolbar-title>
        <v-row width="100%">
            <v-col cols="4" class=" d-flex justify-start align-center ">
            </v-col>
            <v-col cols="4" class="pa-0 d-flex justify-center align-center">
                <transition name="fade" mode="out-in">
                    <v-toolbar-title class=" hidden-md-and-up trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'white--text'" :key="componente.secundario">
                        {{TitleVista}}
                    </v-toolbar-title>
                </transition>
            </v-col>
            <v-col cols="4" class="pa-0 d-flex justify-end align-center">
                
                <!--<v-menu offset-y bottom left class="rounded-lg" flat origin="top right" transition="scale-transition" content-class="elevation-0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="white">
                            <v-icon>mdi-bell-outline</v-icon>
                        </v-btn>
                    </template>
                    <v-card outlined>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title>Sin Notificaciones</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>-->
            </v-col>
        </v-row>
    </v-app-bar>
    <v-container class="pa-0 loader-bar" v-if="!loader">
        <v-row class="mx-0 hidden-md-and-up">
            <v-col cols="12" class="px-3">
                <v-expansion-panels flat class="rounded-lg install_banner" v-if="sistema.a2hs.event"> 
                    <v-expansion-panel class="rounded-lg mb-3">
                        <v-expansion-panel-header class="py-1 pl-0 rounded-lg" expand-icon="mdi-download-circle-outline" disable-icon-rotate :color="TemaDark?'grey darken-4':'white'">
                            <v-list class="py-0 rounded-lg list_banner" :color="TemaDark?'grey darken-4':'white'">
                                <v-list-item :color="TemaDark?'grey darken-4':'white'">
                                    <v-list-item-avatar>
                                        <v-img src="../../public/img/icons/android-chrome-192x192.png" max-width="40" eager contain class="mx-auto text-center img-login" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="trenos-title-card" style="font-size: 1.2rem;">Instalar Trenos</v-list-item-title>
                                        <v-list-item-subtitle>Instalar como app</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="rounded-lg" eager>
                            <v-card :color="TemaDark?'grey darken-4':'white'" flat class="rounded-lg">
                                <v-card class="text-center" flat :color="TemaDark?'grey darken-4':'white'">
                                    <v-img src="../../public/img/icons/android-chrome-512x512.png" max-width="80" contain class="mx-auto text-center img-login pt-5" />
                                    <p class="trenos-title-toolbar text-center" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-2'">Instala Trenos como app</p>
                                </v-card>
                                <v-card-text class="text-center" v-if="!sistema.a2hs.ios" :color="TemaDark?'grey darken-4':'white'">
                                    <v-btn depressed color="primary" width="70%" @click="InstallApp()">
                                        Instalar
                                    </v-btn>
                                    <v-btn text color="primary" width="70%" @click="sistema.a2hs.dialog = false">
                                        cerrar
                                    </v-btn>
                                </v-card-text>
                                <v-card-text class="text-center" v-else :color="TemaDark?'grey darken-4':'white'">
                                    <v-card outlined :color="TemaDark?'grey darken-3':null">
                                        <span> 1. Presiona el icono de compartir</span>
                                        <v-img src="../../public/img/safari_share.png" max-width="130" contain class="mx-auto text-center img-login pt-5" />
                                        <span> 2. Presiona en 'Agregar a inicio'</span>
                                        <v-img src="../../public/img/a2hs_ios.png" max-width="300" contain class="mx-auto text-center img-login pt-5" />
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-expand-transition>
            <v-row class="pa-0 ma-0" v-if="show_mensaje">
                <v-col cols="12">
                    <v-alert dark dense color="warning" class="mx-auto rounded-lg" max-width="400">
                        <v-row>
                            <v-col class="px-1 pb-0 text-center">
                                <v-avatar color="transparent">
                                    <v-icon dark size="40">
                                        mdi-alert-circle-outline
                                    </v-icon>
                                </v-avatar>
                                <p class="white--text mt-2 trenos-title-card mb-0">{{mensaje}}</p>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col>
                                <v-btn block dark @click="show_mensaje = false" depressed color="warning">Cerrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-row class="mx-0 pa-0 ma-0">
            <v-col md="3" class="hidden-sm-and-down pt-0">
                <v-row align="center" justify="center" class="ma-0">
                    <v-col cols="12" class="pa-0">
                        <v-card class="pa-2 rounded-lg" width="200" max-width="200" flat id="menu_card" >
                            <v-list>
                                <v-list-item-group v-model="componente.secundario" :color="TemaDark?'primary lighten-1':'primary'" mandatory class="rounded-lg">
                                    <v-list-item link :value="vista.nombre" :ripple="{ class: `primary--text` }" class="rounded" @click="History(vista.nombre)" v-for="(vista, index) in menu_data" :key="index">
                                        <v-list-item-icon class="my-2">
                                            <v-icon>{{vista.icono}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="py-1">
                                            <v-list-item-title class="my-0 text-subtitle-1 font-weight-medium">{{VistaNombre(vista.nombre)}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item link value="Perfil" :ripple="{ class: `primary--text` }" class="rounded" @click="History('Perfil')">
                                        <v-list-item-icon class="my-2">
                                            <v-icon>mdi-account-circle-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="my-0 text-subtitle-1 font-weight-medium ">Perfil</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <v-card outlined class="rounded-lg" :color="TemaDark?'grey darken-3':null" v-if="sistema.a2hs.event">
                                <v-expansion-panels flat class="install_banner " :color="TemaDark?'grey darken-3':null"> 
                                    <v-expansion-panel class="mb-0 expansion_banner_install_app rounded-lg">
                                        <v-expansion-panel-header class="py-1 pl-0 rounded-lg" expand-icon="" disable-icon-rotate :color="TemaDark?'grey darken-3':null">
                                            <v-list class="py-0 rounded-lg" :color="TemaDark?'grey darken-3':null">
                                                <v-list-item :color="TemaDark?'grey darken-3':null"> 
                                                    <v-list-item-icon class="mr-1 my-1">
                                                        <v-icon>mdi-download-circle-outline</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content class="py-0 mb-1">
                                                        <v-list-item-title class="trenos-title-card" style="font-size: 1.2rem;">Instalar Trenos</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content  eager>
                                            <v-card :color="TemaDark?'grey darken-3':null" flat class="rounded-lg">
                                                <v-card class="text-center rounded-lg" flat :color="TemaDark?'grey darken-3':null">
                                                    <v-img src="../../public/img/icons/android-chrome-512x512.png" max-width="40" contain class="mx-auto text-center img-login pt-5" />
                                                    <p class="trenos-title-toolbar text-center" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-2'">Instala Trenos como app</p>
                                                </v-card>
                                                <v-card-text class="text-center" v-if="!sistema.a2hs.ios" :class="TemaDark?'grey darken-3':null">
                                                    <v-btn depressed color="primary" width="70%" @click="InstallApp()">
                                                        Instalar
                                                    </v-btn>
                                                    <v-btn text color="primary" width="70%" @click="sistema.a2hs.dialog = false">
                                                        cerrar
                                                    </v-btn>
                                                </v-card-text>
                                                <v-card-text class="text-center" v-else :class="TemaDark?'grey darken-3':null">
                                                    <v-card outlined :color="TemaDark?'grey darken-3':null">
                                                        <span> 1. Presiona el icono de compartir</span>
                                                        <v-img src="../../public/img/safari_share.png" max-width="130" contain class="mx-auto text-center img-login pt-5" />
                                                        <span> 2. Presiona en 'Agregar a inicio'</span>
                                                        <v-img src="../../public/img/a2hs_ios.png" max-width="300" contain class="mx-auto text-center img-login pt-5" />
                                                    </v-card>
                                                </v-card-text>
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md="9" class="py-0">
                <transition name="fade" mode="out-in">
                    <component :is="componente.secundario"></component>
                </transition>
            </v-col>
        </v-row>
        <v-bottom-navigation app v-model="componente.secundario" color="primary" shift class="hidden-md-and-up bottom_nav" mandatory>
            <v-btn :value="vista.nombre" @click="History(vista.nombre)" v-for="(vista,index) in menu_data" :key="index">
                <span>{{VistaNombre(vista.nombre)}}</span>
                <v-icon>{{vista.icono}}</v-icon>
            </v-btn>
            <v-btn value="Perfil" @click="History('Perfil')">
                <span>Perfil</span>
                <v-icon>mdi-account-circle-outline</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-container>
    <v-container v-else class="loading-home">
        <v-row align-content="center" justify="center">
            <v-col class="subtitle-1 text-center" cols="12">
                <span class="white--text">Cargando...</span>
            </v-col>
            <v-col cols="4">
                <v-progress-linear color="white" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</v-main>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import dayjs from "dayjs"

export default {
    components: {
        Usuario: () => import('@/vistas/Usuario'),
        Itinerario: () => import('@/vistas/Itinerario'),
        Buscar: () => import('@/vistas/Buscar'),
        Planilla: () => import('@/vistas/Planilla'),
        Perfil: () => import('@/vistas/Perfil')
    },
    data() {
        return {
            reload: false,
            menu_data: {},
            loader: true,
            mensaje: null,
            show_mensaje: false
        }
    },
    computed: {
        ...mapState(['componente', 'snackbar', 'usuario', 'sistema','dialog']),
        TitleVista() {
            if (this.componente.secundario == 'Usuario') {
                return 'Calendario'
            } else {
                return this.componente.secundario
            }
        },
        Vista() {
            return this.componente.secundario
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        }
    },
    methods: {
        ...mapMutations(['LogOut', 'CloseAllDialog', 'ErrorRequest','UpdateComponente','UltimaVista','CloseDialog']),
        History(value) { //Regista un cambio de vista.
            if(window.history.state == value){
                switch (value) {
                    case 'Usuario':
                        this.UpdateComponente('calendario')
                        this.UpdateComponente('turno')
                        break
                    case 'Buscar':
                        this.UpdateComponente('buscar')
                        break
                    case 'Itinerario':
                        this.UpdateComponente('itinerario')
                        break
                    case 'Planilla':
                        this.UpdateComponente('planilla')
                        break
                    case 'Perfil':
                        this.UpdateComponente('perfil')
                        break
                    default:
                        break
                }
            }
            this.UltimaVista(value)
        },
        async GetData() {
            await this.axios.get(`/menu/`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (!respuesta.data.menu) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    if(respuesta.data.mensaje){
                        this.mensaje = respuesta.data.mensaje.mensaje
                        this.show_mensaje = true
                    }
                    clearInterval(this.sistema.hora_interval)
                    this.menu_data = respuesta.data.menu
                    let server_time = respuesta.data.datetime
                    //obtiene la diferencia del tiempo del server con la local (unix)
                    this.sistema.diff = server_time - dayjs().unix()
                    this.sistema.hora = dayjs().add(this.sistema.diff, 'seconds').format('HH:mm:ss')
                    this.sistema.hora_interval = setInterval(() => { this.sistema.hora = dayjs().add(this.sistema.diff, 'seconds').format('HH:mm:ss')}, 1000)
                    if (window.history.state) {
                        if(window.history.state !== 'Login') this.componente.secundario = window.history.state
                    }
                    this.loader = false
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        VistaNombre(nombre) {
            if (nombre == 'Usuario') nombre = 'Calendario'
            return nombre
        },
        async InstallApp() {
            this.sistema.a2hs.dialog = false
            this.sistema.a2hs.event.prompt()
            this.sistema.a2hs.event = null
        },
        UltimaVista(nueva_vista){ //Guarda la ultina vista seleccionada por el usuario
            let vista_guardada = sessionStorage.getItem("vista")
            if(vista_guardada) {
                if(vista_guardada != nueva_vista ) {
                    sessionStorage.setItem("vista", nueva_vista)
                }
            }else{
                sessionStorage.setItem("vista", nueva_vista)
            }
        },
        
    },  
    created() { //Comprueba si existe algun registro en api history.
        this.GetData()
    }
}
</script>

<style scoped>
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 16px;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
    margin-bottom: 0px;
}

.card-menu {
    border: 0;
    max-width: 250px;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
    border-radius: 4px;
}

.v-list-item {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 36px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
}

@media (min-width: 1904px) {
    .container {
        max-width: 1185px;
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 1024px;
    }

    .v-app-bar {
        height: calc(64px + env(safe-area-inset-top)) !important;
        padding-top: calc(env(safe-area-inset-top)) !important;
    }
}

@media (max-width: 959px) {
    .container {
        max-width: 1024px;
    }

    .v-app-bar {
        height: calc(64px + env(safe-area-inset-top)) !important;
        padding-top: calc(env(safe-area-inset-top)) !important;
    }
}

.v-item-group.v-bottom-navigation .v-btn {
    min-width: calc(100%/5);
}

.theme--light.bottom_nav {
    padding-left: .5rem;
    padding-right: .5rem;
    box-shadow: none !important;
    border-top: solid 1px #e0e0e0 !important;
    padding-bottom: calc(2.5rem + env(safe-area-inset-bottom)) !important;
}

.theme--dark.bottom_nav {
    padding-left: .5rem;
    padding-right: .5rem;
    box-shadow: none !important;
    border-top: none;
    padding-bottom: calc(2.5rem + env(safe-area-inset-bottom)) !important;
}

#menu_card {
    position: fixed;
    /*top: 5.5rem;*/
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #757575 !important;
}

.trenos-title-toolbar {
    font-family: 'Product Sans' !important;
    font-size: 1.5rem;
}

#content {
    /*padding-top: 1rem !important;*/
    margin-top: env(safe-area-inset-top) !important;
    margin-left: env(safe-area-inset-left) !important;
    margin-right: env(safe-area-inset-right) !important;
    margin-bottom: env(safe-area-inset-bottom) !important;

}

.install-app-chip {
    border-radius: 16px;
    font-size: 18px;
    height: 43px;
}

.loading-home {
    margin-top: calc(20vh + env(safe-area-inset-top)) !important;
}


.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before{
    border-radius: 8px;
}

.theme--dark.v-list-item:focus::before {
     border-radius: 8px;
}

.theme--dark.v-list-item:hover::before {
     border-radius: 8px;
}

.install_banner{
    z-index: 3 !important;
}

.theme--dark.v-expansion-panels .v-expansion-panel.expansion_banner_install_app{
    background-color: #424242 !important;
}

</style>
